import React, { useEffect } from 'react'



import { useNavigate } from 'react-router-dom'
import { Button, CardActionArea, CardActions } from '@mui/material';

import moment from 'moment';


function EventCard(props) {
  const navigate = useNavigate();
  return (
    // <div
    //   key={props.index}
    //   className='my-1 px-1 w-full md:w-1/2  md:mb-6  lg:my-4 lg:px-4 lg:w-1/3'>
    //   <article
    //     style={{ boxShadow: "0px 10px 30px 0px rgba(0, 0, 0, 0.15)" }}
    //     className='overflow-hidden p-[1rem] bg-[#ffffff] '>
    //     <div>
    //       <a href='#'>
    //         <img
    //           alt='Placeholder'
    //           className='block h-[300px] w-full'
    //           src={props.source}
    //         />
    //       </a>
    //       <div className='box  bg-black text-center p-2'>
    //         <h1 className='text-white text-lg font-bold'>{props.eventDate}</h1>
    //       </div>
    //       <header className='flex items-center justify-between leading-tight mt-4'>
    //         <h1 className='text-lg font-black'>{props.title}</h1>
    //         <p className='text-grey-darker font-black text-sm'>
    //           ₹{props.price}
    //         </p>
    //       </header>
    //       <body className='mt-4'>
    //         <p className=' text-sm line-clamp-3 '>{props.shortDesc}</p>
    //       </body>
    //       <div
    //         key={props.index}
    //         className='my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3'></div>
    //     </div>
    //     <footer className=''>
    //       <div className='mt-4'>
    //         <Button
    //           className='w-full '
    //           size='large'
    //           onClick={() => navigate(props.navigate)}
    //           variant='contained'
    //           style={{
    //             backgroundColor: "#61D4FB ",
    //             color: "black",
    //             fontWeight: "bold",
    //           }}>
    //           Buy Tickets
    //         </Button>
    //       </div>
    //     </footer>
    //   </article>
    // </div>

    <div
      key={props.index}
      className='w-full flex flex-col p-[1rem] gap-[1.5rem] h-[520px] overflow-auto'
      style={{
        boxShadow: "0px 10px 30px 0px rgba(0, 0, 0, 0.15)",
        background: "#fff",
      }}>
      <div className='flex flex-col justify-between gap-[1rem] h-full'>
        <div className='flex flex-col gap-[1rem] '>
          <div className='w-full flex flex-col gap-2 items-center justify-center'>
              <img
                alt='Placeholder'
                className='block h-[135px] lg:h-[150px]'
                src={props.source}
              />
            <div className='box  bg-black text-center p-2 w-full'>
              <h1 className='text-white text-lg font-bold'>
                {props.eventDate}
              </h1>
            </div>
          </div>

          <div className='flex flex-col gap-[0.5rem]'>
            <div className='flex flex-row justify-between '>
              <h1 className='font-raleway text-[1rem] xl:text-[1.2rem] 2xl:text-[1.5rem] font-bold lg:font-[800] text-[#060606] leading-[99%] '>
                {props.title}
              </h1>

              <p className='font-raleway text-[1rem] xl:text-[1.2rem] 2xl:text-[1.5rem] font-bold lg:font-[800] text-[#060606] leading-[99%] '>
                ₹{props.price}
              </p>
            </div>

            <p className='font-raleway text-[0.8rem] lg:text-[1rem] font-normal text-[#060606] 
            line-clamp-5 leading-[1.7rem] '>
              {props.shortDesc}
            </p>
          </div>
        </div>
        <button
          onClick={() => navigate(props.navigate)}
          className='bg-[#61D4FB] hover:bg-[#0074BE] hover:text-[#fff] py-[0.9rem] font-raleway text-[1rem] font-[700] leading-[99%] text-[#101010]'
          style={{ boxShadow: " 0px 10px 30px 0px rgba(13, 13, 13, 0.15)" }}>
          Buy Tickets
        </button>
      </div>
    </div>
  );
}

export default EventCard